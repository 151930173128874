import { StudioConfigService } from './studio-config.service';
import { ConfigurationService } from './configuration.service';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeTranscriptionDisplayComponent } from './home/home-transcription-display.component';
import { InteractionViewerComponent } from './interaction-viewer/interaction-viewer.component';
import { TranscriptionComponent } from './transcription/transcription.component';
import { UILibraryModule } from '@amc-technology/ui-library';
import { LoggerService } from './logger.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeTranscriptionDisplayComponent,
    InteractionViewerComponent,
    TranscriptionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    UILibraryModule,
    HttpClientModule
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory:
        (configService: ConfigurationService, loggerService: LoggerService, studioConfigService: StudioConfigService) =>
        async () => {
          await configService.loadConfigurationData();
          await loggerService.initialize();
          await studioConfigService.getStudioConfig();
        },
        deps: [ConfigurationService, LoggerService, StudioConfigService],
        multi: true
    },
    LoggerService,
    StudioConfigService],
  bootstrap: [AppComponent]
})
export class AppModule { }
