import { Injectable } from '@angular/core';
import { IConfiguration } from './Model/IConfiguration';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private readonly configUrlPath: string = 'ClientConfiguration';
  private baseUrl: string = '';
  private http: HttpClient;
  public configData: IConfiguration | undefined;


  constructor(http: HttpClient,  @Inject('BASE_URL') baseUrl: string) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  async loadConfigurationData(): Promise<IConfiguration> {
    try {
      if (this.configData === undefined) {
        console.log('Loading configuration data');
        this.configData = await firstValueFrom(this.http.get<IConfiguration>(this.baseUrl + this.configUrlPath));
      }
      console.log(this.configData);
      return this.configData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
