import { ERROR_CODE, LOG_LEVEL, Logger } from '@amc-technology/davinci-api';

import { ConfigurationService } from './configuration.service';
import { Injectable } from '@angular/core';

@Injectable()
export class LoggerService {
  private localLog = false; // Set this to true if you want to print logs to the console
  public logger!: Logger;
  constructor(private configService: ConfigurationService) {
    this.initialize();
  }

  async initialize() {
    const serverConfig = await this.configService.loadConfigurationData();
    this.localLog = serverConfig.useDevLogger == 'true';
    this.logger = new Logger(
      'DaVinciContactCenter',
      false,
      serverConfig.loggerApiUrl
    );
    this.logger.logDebug('[START] Loading app');
  }

  /**
   * Logs a message to the loggerApi
   *
   * @param {LOG_LEVEL} logLevel - The level of the log
   * @param {string} fName - Name of the function logging the message
   * @param {string} message - Message to log
   * @param {*} [object] - Optional object to log
   * @param {ERROR_CODE} [errorCode] - Optional error code
   * @param {Date} [localTime] - Optional local time
   * @memberof LoggerService
   */
  async log(logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) {
    const functionName = 'log';
    try {
      if (this.localLog) {
        this.localLogMessage(logLevel, fName, message, object, errorCode, localTime);
      }
      switch (logLevel) {
        case LOG_LEVEL.Loop:
          this.logger.logLoop(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
          break;
        case LOG_LEVEL.Trace:
          this.logger.logTrace(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
          break;
        case LOG_LEVEL.Information:
          this.logger.logInformation(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
          break;
        case LOG_LEVEL.Warning:
          this.logger.logWarning(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
          break;
        case LOG_LEVEL.Error:
          this.logger.logError(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
          break;
        case LOG_LEVEL.Critical:
          this.logger.logCritical(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
          break;
        case LOG_LEVEL.Debug:
          this.logger.logDebug(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
          break;
        default:
          this.logger.logInformation(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, Object.getOwnPropertyNames(object))}` : ``), errorCode, localTime);
          break;
      }
    } catch (e) {
      this.logger.logError(`${functionName} - Failed to log ${logLevel} from ${fName}. Error: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      console.log(`${functionName} - Failed to log ${logLevel} from ${fName}. Error: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  /**
   * This function logs a message to the console that is color coded based off of its LOG_LEVEL
   *
   * @private
   * @param {LOG_LEVEL} logLevel
   * @param {string} fName
   * @param {string} message
   * @param {*} [object]
   * @param {ERROR_CODE} [errorCode]
   * @param {Date} [localTime]
   * @memberof LoggerService
   */
  private localLogMessage(logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) {
    const functionName = 'localLogMessage';
    try {
      let backgroundColor = '#003300';
      let textColor = '#00ff00';

      switch (logLevel) {
        case LOG_LEVEL.Loop:
        case LOG_LEVEL.Trace:
          backgroundColor = '#000033';
          textColor = '#0077ff';
          break;
        case LOG_LEVEL.Information:
        case LOG_LEVEL.Debug:
          backgroundColor = '#003300';
          textColor = '#00ff00';
          break;
        case LOG_LEVEL.Warning:
          backgroundColor = '#333300';
          textColor = '#ffff00';
          break;
        case LOG_LEVEL.Error:
        case LOG_LEVEL.Critical:
          backgroundColor = '#330000';
          textColor = '#ff0000';
          break;
        default:
          backgroundColor = '#003300';
          textColor = '#00ff00';
          break;
      }
      const fontWeight = 'bold';
      if (object != null && typeof object === 'object') {
        if (logLevel === LOG_LEVEL.Loop || logLevel === LOG_LEVEL.Trace) {
          console.groupCollapsed(`%c${fName} | ${LOG_LEVEL[logLevel]} | ${message}`, `background: ${backgroundColor}; color: ${textColor}; font-weight: ${fontWeight};`);
        } else {
          console.group(`%c${fName} | ${LOG_LEVEL[logLevel]} | ${message}`, `background: ${backgroundColor}; color: ${textColor}; font-weight: ${fontWeight};`);
        }
        const printableObject = {
          functionName: fName,
          message: message,
          object: object
        };
        console.log(printableObject);
        console.groupEnd();
      } else {
        console.log(`%c${fName} | ${LOG_LEVEL[logLevel]} | ${message}${object != null ? ` | ${object}` : ''}`, `background: ${backgroundColor}; color: ${textColor}; font-weight: ${fontWeight};`);
      }
    } catch (e) {
      console.log(`${functionName} - Failed to log ${logLevel} from ${fName}. Error: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  private stringifyReplacer() {
    const ancestors: any[] = [];
    return  (key: any, value: any) => {
      if (typeof value !== 'object' || value === null) {
        if (value instanceof Set) {
          return [...value];
        }
        if (key === 'promise') {
          return {};
        }
        return value;
      } else {
        while (ancestors.length > 0 && ancestors[ancestors.length - 1] !== this) {
          ancestors.pop();
        }
        if (ancestors.includes(value)) {
          return '[Circular]';
        }
        ancestors.push(value);
        return value;
      }
    };
  }
}
