import {
  ANALYTICS_TYPES,
  CHANNEL_TYPES,
  ERROR_CODE,
  IActivity,
  IInteraction,
  INTERACTION_STATES,
  LOG_LEVEL,
  SearchLayouts,
  SearchRecords,
  USER_TYPES,
  initializeComplete,
  queueAction,
  registerOnInteraction,
  setAppHeight,
} from '@amc-technology/davinci-api';
import { Component, OnInit } from '@angular/core';
import { IChatMessage, IChatMessageType, IScenario, IInteraction as UILibraryIInteraction } from '@amc-technology/ui-library';

import { LoggerService } from '../logger.service';
import { StudioConfigService } from '../studio-config.service';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-home',
  templateUrl: './home-transcription-display.component.html',
  styleUrls: ['./home-transcription-display.component.scss'],
})
export class HomeTranscriptionDisplayComponent implements OnInit {
  public activeInteractions: {
    interactionId: string;
    interaction: IInteraction;
  }[] = [];
  public completedInteractions: {
    interactionId: string;
    interaction: IInteraction;
  }[] = [];
  public interactionHistory: {
    interactionId: string;
    interactions: IInteraction[];
  }[] = [];
  public currentInteraction: IInteraction | undefined;
  public inputField: any;
  public clickToActOperation: string | undefined;
  public scenario: IScenario | undefined;
  public transcriptInteraction!: UILibraryIInteraction;
  public messages: IChatMessage[] = [];
  public isOpenAI: boolean = false;

  constructor(private loggerService: LoggerService, private studioConfigService: StudioConfigService) {
    loggerService.initialize();
  }

  async ngOnInit() {
    const functionName = 'ngOnInit';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Initializing Davinci Service`);
      registerOnInteraction(this.onInteraction.bind(this));
      initializeComplete();
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Setting App Height to ${this.studioConfigService.appHeight}`);
      setAppHeight(this.studioConfigService.appHeight);
      await this.configSetup();
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Error initializing Davinci Service`, error);
    }
  }

  async configSetup() {
    const functionName = 'configSetup';
    try {
      if (this.studioConfigService.enableOpenAi) {
        this.isOpenAI = true;
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, 'Failed to get studio configs');
    }
  }

  onInteraction(interaction: IInteraction): Promise<SearchRecords> {
    const functionName = 'onInteraction';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Interaction received`, interaction);
      this.processInteraction(interaction);
      return Promise.resolve(new SearchRecords());
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Error processing interaction`, error);
      return Promise.reject(error);
    }
  }

  private processInteraction(interaction: IInteraction) {
    const functionName = 'processInteraction';
    try {
      if (interaction.state === INTERACTION_STATES.Disconnected) {
        this.completedInteractions.push({
          interactionId: interaction.interactionId,
          interaction,
        });
        this.activeInteractions.splice(
          this.activeInteractions.findIndex((i) => i.interactionId === interaction.interactionId),
          1
        );
        this.currentInteraction = undefined;
        this.cleanUpTranscripts();
      } else {
        if (this.activeInteractions.find((i) => i.interactionId === interaction.interactionId)) {
          this.activeInteractions.find((i) => i.interactionId === interaction.interactionId)!.interaction = interaction;
          if (interaction.state === INTERACTION_STATES.Connected && this.currentInteraction?.state === INTERACTION_STATES.Alerting) {
            this.createScenario(interaction);
          } else {
            this.updateTranscriptChat(interaction);
            return;
          }
        } else {
          // On new interaction.
          this.activeInteractions.push({
            interactionId: interaction.interactionId,
            interaction,
          });
        }
        this.currentInteraction = interaction;
      }
      if (this.interactionHistory.find((i) => i.interactionId === interaction.interactionId)) {
        this.interactionHistory.find((i) => i.interactionId === interaction.interactionId)?.interactions.push(interaction);
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Error processing interaction`, error);
    }
  }

  protected isToolbarVisible(): Promise<boolean> {
    // throw new Error('Method not implemented.');
    return Promise.resolve(true);
  }
  protected saveActivity(activity: IActivity): Promise<string> {
    throw new Error('Method not implemented.');
  }
  protected getSearchLayout(): Promise<SearchLayouts> {
    throw new Error('Method not implemented.');
  }
  protected formatCrmResults(crmResults: any): SearchRecords {
    throw new Error('Method not implemented.');
  }

  public createScenario(newInteraction: IInteraction): void {
    const heightOffset = this.isOpenAI ? 165 : 90;
    this.transcriptInteraction = {
      interactionId: newInteraction.interactionId,
      startTime: new Date().getTime(),
      displayCallTimer: false,
      subheaderData: {
        image: new URL('https://amcdavincistorage.blob.core.windows.net/icon-pack/Phone_Number_Icon.png'),
        // tooltip: 'Phone',
        value: newInteraction.details?.fields['Phone'].Value,
      },
      UIHeadersData: {
        maximizeUrl: new URL('https://amcdavincistorage.blob.core.windows.net/icon-pack/section_expand.png'),
        minimizeUrl: new URL('https://amcdavincistorage.blob.core.windows.net/icon-pack/section_collapse.png'),
        directionText: '',
        displayHoldCounter: false,
        holdCounterData: {
          currentHoldStartTime: new Date().getTime(),
        },
        statusText: 'Call Transcripts',
        statusUrl: new URL('https://amcdavincistorage.blob.core.windows.net/icon-pack/Phone_Number_Icon.png'),
      },
      chat: {
        messages: this.messages,
        isCustomerTyping: false,
        settings: {
          sendImage: new URL('https://amcdavincistorage.blob.core.windows.net/icon-pack/request_send.png'),
          maxHeight: this.studioConfigService.appHeight - heightOffset + 'px',
          // 29 - 30 - 17 - 80 - 5
          hideSendMessage: !this.isOpenAI,
          disableSendMessage: false,
          sendMessageText: this.studioConfigService.sendButtonText
        },
      },
    };
    this.scenario = { interactions: [this.transcriptInteraction] };
  }

  public updateTranscriptChat(interaction: IInteraction) {
    // How can I add the transcript messages array.

    if (interaction.transcripts?.data !== undefined) {
      try {
        console.log('\x1b[36m%s\x1b[0m', interaction.transcripts.data);
        if (interaction.analytics?.analytics) {
          for (let analysis of interaction.analytics.analytics) {
            let analysisType = 'Virtual Agent Response';  // Default to Virtual Agent as analysis type
            if (analysis.type === ANALYTICS_TYPES.Sentiment) {
              analysisType = 'Sentiment';
            } else if (analysis.type === ANALYTICS_TYPES.PII) {
              analysisType = 'PII';
            }
            let formatData: string;
            if (analysis.type === ANALYTICS_TYPES.VirtualAgent && interaction.transcripts?.context?.userType === USER_TYPES.Agent) {
              formatData = `----------<br><b>Question:</b> ${interaction.transcripts?.data}<br><b>${analysisType} Response:</b> ${analysis.data}<br>----------`;
            } else {
              formatData = `----------<br><b>Client Transcription:</b> ${interaction.transcripts?.data} <br><b>${analysisType}:</b> ${analysis.data}<br>----------`;
            }
            this.pushMessageToScenario(formatData, IChatMessageType.INFORMATION, 'Agent Question');
          }
        } else {
          const speaker: IChatMessageType = interaction.transcripts.context.userType === USER_TYPES.Client ? IChatMessageType.OTHER_PERSON : IChatMessageType.AGENT;
          let username: string = interaction.transcripts.context.userType === USER_TYPES.Client ? interaction.details?.fields['Phone'].Value : 'Agent';
          let message: string = interaction.transcripts.data;
          if (interaction.analytics !== undefined) {
            for (let analysis of interaction.analytics?.analytics) {
              if (analysis.type === ANALYTICS_TYPES.Sentiment) {
                switch (analysis.data) {
                  case 'positive':
                    message = message + '<span> &#128512;</span>';
                    break;
                  case 'negative':
                    message = message + '<span>&#128545;</span>';
                    break;
                  case 'neutral':
                    message = message + '<span>&#128528;</span>';
                    break;
                }
              }
            }
          }
          // message =
          //   message +
          //   '<br><br>' + 'SENTIMENT: ' +
          //   interaction.details?.fields['analysis'].Value;
          // }
          this.pushMessageToScenario(message, speaker, username);

        }
      } catch (error) {
        this.loggerService.log(LOG_LEVEL.Error, 'updateTranscriptChat', 'Error updating chat', error);
      }
    }
  }

  pushMessageToScenario(message: string, speaker: IChatMessageType, username: string) {
    if (this.scenario?.interactions[0] !== undefined) {
      this.scenario?.interactions[0].chat?.messages.push({
        text: message,
        type: speaker,
        timestamp: new Date().toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        }),
        username: username,
      });
    } else {
      this.loggerService.log(LOG_LEVEL.Error, 'pushMessageToScenario', 'Scenario is undefined');
    }
  }

  // Resets objects used for chat scenario and transcripts.
  public cleanUpTranscripts() {
    this.messages = [];
    this.scenario = {
      interactions: [],
    };
  }

  // Theoretically this should only be used when there is Azure Open AI
  public async newMessage(message: string, scenario: IScenario) {
    const functionName = 'newMessage';
    try {
      this.loggerService.logger.logTrace(`${functionName} : Agent asked a question`);
      let formattedMessage = '----------<br><b>Question:</b><br>' + message + '<br>----------';
      const speaker = IChatMessageType.INFORMATION;
      if (scenario.interactions[0].interactionId === this.currentInteraction?.interactionId) {
        let interaction = await this.formatQuestionInteraction(this.currentInteraction, message);
        queueAction(interaction);
      } else {
        let matchingInteraction;
        for (let interaction of this.activeInteractions) {
          if (interaction.interactionId === scenario.interactions[0].interactionId) {
            matchingInteraction = interaction.interaction;
          }
        }
        if (matchingInteraction !== undefined && matchingInteraction !== null) {
          matchingInteraction = await this.formatQuestionInteraction(matchingInteraction, message);
          queueAction(matchingInteraction);
        } else {
          this.loggerService.logger.logError(`${functionName} : No matching interaction for scenario. Question not sent`, ERROR_CODE.Other); // Might want to change this
        }
      }
      this.pushMessageToScenario(formattedMessage, speaker, 'Agent Question');
    } catch (e) {
      this.loggerService.logger.logError(`${functionName} : Send Message Failed. Please try again.`, ERROR_CODE.Other); // Might want to change this
    }
  }

  public async formatQuestionInteraction(interaction: IInteraction, message: string): Promise<IInteraction | void> {
    const functionName = 'formatQuestionInteraction';
    try {
      let newInteraction = interaction;
      newInteraction.transcripts = {
        id: uuid(),
        isComplete: false,
        data: message,
        context: {
          email: '',
          username: '',
          firstName: '',
          lastName: '',
          attributes: '',
          profiles: [],
          userType: USER_TYPES.Agent,
        },
      };
      return interaction;
    } catch (e) {
      this.loggerService.logger.logError(`${functionName} : Formatting Interaction from question failed.`, ERROR_CODE.Other); // Might want to change this
    }
  }
}
