import { Component } from '@angular/core';
import { ITranscription } from './Model/ITranscription';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Transcription';

  // relation: string;
  // transcript: string;
  // analysis: string;
  // transcriptions: ITranscription[] = [];

}
