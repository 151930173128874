import { Component, OnInit, Input } from '@angular/core';
import { ITranscription } from '../Model/ITranscription';
import {
  IInteraction,
  IScenario,
  IInteractionUIHeaders,
  IPartyHeader,
  IChatMessage
} from '@amc-technology/ui-library';

@Component({
  selector: 'app-transcription',
  templateUrl: './transcription.component.html',
  styleUrls: ['./transcription.component.scss']
})
export class TranscriptionComponent implements OnInit {

  // @Input() scenario: IScenario;
  constructor() { }

  ngOnInit(): void {
    // window.setInterval(() => {
    //   this.scenario.interactions[0].chat.messages.push({
    //     username: `bob@example.com`,
    //     userIcon: new URL(`https://randomuser.me/api/portraits/men/32.jpg`),
    //     text: 'Hello?',
    //     timestamp: '8:01pm'
    //   });
    // }, 2000);
  }

  // @Input() transcription: ITranscription;
  // newMsg: IChatMessage[];

  // url: URL = {
  //   hash: '',
  //   host: '',
  //   hostname: '',
  //   href: '',
  //   origin: '',
  //   password: '',
  //   pathname: '',
  //   port: '',
  //   protocol: '',
  //   search: '',
  //   searchParams: undefined,
  //   username: '',
  //   toJSON: function (): string {
  //     throw new Error('Function not implemented.');
  //   }
  // };

  // headerData: IInteractionUIHeaders = {
  //   minimizeUrl: this.url,
  //   maximizeUrl: this.url,
  //   statusUrl: this.url,
  //   statusText: '',
  //   directionText: '',
  //   displayHoldCounter: false
  // };

  // partyHeader: IPartyHeader = { value: "I don't party." };

  // interaction: IInteraction = {
  //   interactionId: 'hi',
  //   startTime: 42,
  //   displayCallTimer: false,
  //   UIHeadersData: this.headerData,
  //   subheaderData: this.partyHeader
  // };

  // interactions: IInteraction[] = [this.interaction];

  // scenario: IScenario = {
  //   interactions: this.interactions
  // };

  // newMessage(message: string, scenario: IScenario) {
  //   console.log(message);
  // }


}
