import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Add a path to the home component as the default route.
const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
