import {
  ERROR_CODE,
  LOG_LEVEL,
  NOTIFICATION_TYPE,
  getConfig,
  sendNotification
} from '@amc-technology/davinci-api';

import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class StudioConfigService {

  private root = {
    enableOpenAi: false,
    appHeight: 400,
    sendButtonText: 'Ask'
  }
  enableOpenAi: boolean = false;
  appHeight: number = 400;
  sendButtonText: string = 'Ask';

  private studioConfig = {
    variables: this.root
  }

  constructor(private loggerService: LoggerService) {
  }

  async getStudioConfig(): Promise<any> {
    const functionName = 'getStudioConfig';
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, 'Obtaining Studio Configurations');
      const config = await getConfig();
      this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Studio Configs: ', config);
      this.setConfigs(config);
      this.loggerService.log(LOG_LEVEL.Information, functionName, 'Studio Configurations obtained.');
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, 'Error getting Studio Configurations', error);
    }
  }

  private setConfigs(config: any): void {
    const functionName: string = 'setConfigs';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Updating Client Configs with values from Studio');
      this.setRootConfigs(config.variables);
      this.updateStudioConfig();
      this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Updated Client Config', this.studioConfig);
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, 'Error setting Studio Config', error);
    }
  }

  private setRootConfigs(config: any): void {
    const functionName: string = 'setRootConfigs';
    try {
      if (!config) {
        throw new Error('Missing Root Configs');
      }
      this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Checking Root Configs');
      this.enableOpenAi = this.updateConfigs('enableOpenAi', this.root.enableOpenAi, config.enableOpenAi);
      this.appHeight = this.updateConfigs('appHeight', this.root.appHeight, config.appHeight);
      this.sendButtonText = this.updateConfigs('sendButtonText', this.root.sendButtonText, config.sendButtonText);
      this.loggerService.log(LOG_LEVEL.Trace, functionName, 'Root Config', this.root);
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, 'Error setting Root Config', error);
    }
  }

  private updateStudioConfig(): void {
    const functionName: string = 'updateStudioConfig';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Updating Studio Config' )
      this.studioConfig.variables = this.root;
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, 'Error updating Studio Config', error);
    }
  }

  /**
 * this function will compare the values of the default config and the studio config and return the correct value
 *
 * @private
 * @param {*} defaultConfig
 * @param {*} studioConfig
 * @return {*}  {void}
 * @memberof StudioConfigService
 */
  private updateConfigs(configName: string, defaultConfig: any, studioConfig: any, critical: boolean = false, pii: boolean = false): any {
    const functionName: string = 'updateConfigs';
    try {
      this.loggerService.log(LOG_LEVEL.Loop, functionName, 'Comparing Configs', { configName, defaultConfig, studioConfig });
      if (studioConfig != null && typeof defaultConfig === typeof studioConfig) {
        this.loggerService.log(LOG_LEVEL.Loop, functionName, 'Valid Studio Config', pii ? { configName } : { configName, studioConfig });
        return studioConfig;
      } else {
        this.loggerService.log(critical ? LOG_LEVEL.Critical : LOG_LEVEL.Error, functionName, 'Invalid Studio Config, using Default Config', pii ? { configName, defaultConfig } : { configName, defaultConfig, studioConfig });
        if (critical) {
          sendNotification('Configuration Error. Please contact your administrator for assistance.', NOTIFICATION_TYPE.Error);
        }
        return defaultConfig;
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, 'Error comparing Configs', error);
    }
  }
}
