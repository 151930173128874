import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IInteraction, INTERACTION_STATES } from '@amc-technology/davinci-api';

@Component({
  selector: 'app-interaction-viewer',
  templateUrl: './interaction-viewer.component.html',
  styleUrls: ['./interaction-viewer.component.scss']
})
export class InteractionViewerComponent implements OnInit {
  @Input() interaction!: IInteraction;
  interactionId!: string;
  scenarioId!: string;
  state!: string;

  showDetails = false;

  constructor() {;
  }

  ngOnInit(): void {
    this.interactionId = this.interaction.interactionId;
    this.scenarioId = this.interaction.scenarioId ? this.interaction.scenarioId : 'No Scenario Id';
    this.state = this.interaction.state !== undefined ? INTERACTION_STATES[this.interaction.state] : 'No State';
  }

  ngOnChanges(): void {
    this.interactionId = this.interaction.interactionId;
    this.scenarioId = this.interaction.scenarioId ? this.interaction.scenarioId : 'No Scenario Id';
    this.state = this.interaction.state !== undefined ? INTERACTION_STATES[this.interaction.state] : 'No State';
  }

  public details(): void {
    this.showDetails = !this.showDetails;
  }
}
